<template>
  <ContentCard>
    <div class="flex h-120">
      <div
        class="w-72 flex-shrink-0 border-r-2 border-gray-light flex flex-col"
      >
        <div
          class="p-4 pb-2 border-b-2 border-gray-light flex-grow-0 flex justify-between items-center"
        >
          <h3 class="font-display1 text-gray-dark text-base font-light">
            {{ title }}
          </h3>
          <div>
            <slot name="extras" />
          </div>
        </div>
        <div
          class="flex flex-col justify-between overflow-x-scroll flex-shrink h-full"
        >
          <ul class="list-select-list">
            <li
              v-for="item in items"
              v-bind:key="item.id"
              class="flex justify-between items-center"
              :class="item.id === active ? 'active' : ''"
              @click.prevent="$emit('select', item)"
            >
              <div class="pl-4 py-4 min-w-0">
                <slot name="listItem" v-bind:item="item">{{ item.id }}</slot>
              </div>
              <div class="pr-2">
                <i class="material-icons-outlined md-24 micon text-gray-dark"
                  >keyboard_arrow_right</i
                >
              </div>
            </li>
          </ul>
          <div
            v-if="hasMore || loading"
            class="h-8 flex-shrink-0 load-more overflow-hidden"
          >
            <a
              href="#!"
              @click.prevent="$emit('loadMore')"
              v-if="hasMore && !loading"
              class="flex flex-col items-center text-gray-dark no-underline hover:text-gray-darkest"
            >
              <div class="text-tiny -mb-2">Load More</div>
              <i class="material-icons-outlined md-24 micon">expand_more</i>
            </a>
            <div v-if="loading" class="text-center">
              <ToznyLoader v-if="loading" class="w-8 h-8 text-tozny" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow min-w-0">
        <slot name="activeItem" v-bind:item="activeItem"></slot>
      </div>
    </div>
  </ContentCard>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'ListSelect',
  components: {
    ContentCard,
    ToznyLoader,
  },
  props: {
    title: String,
    hasMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      validator: (items) => items.every((item) => !!item.id),
    },
  },
  computed: {
    activeItem() {
      let item = false
      for (let i of this.items) {
        if (i.id === this.active) {
          item = i
          break
        }
      }
      return item
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/tailwind-variables.scss';

.list-select-list li {
  cursor: pointer;
  &:hover {
    background-color: $colors-gray-lightest;
  }
  &.active {
    background-color: $colors-tozny;
  }
}
.list-select-list li.active * {
  color: $colors-white !important;
}
.load-more {
  flex-basis: 2.5rem;
}
</style>
