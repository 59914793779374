<template>
  <div class="px-4 py-4 border-t-2 border-gray-medium">
    <p class="text-gray-dark">
      Provide a JSON string of this client's credentials to unlock available
      actions.
    </p>
    <ToznyForm :loading="loading" :error="formErrors" :on-submit="handleSubmit">
      <ToznyTextarea
        id="credentials"
        rows="12"
        class="mb-4"
        input-class="bg-gray-lightest border-transparent"
        :placeholder="credPlaceholder"
        v-model="credentials"
        monospace
      />
      <div class="flex justify-end">
        <ToznyButton class="mb-0" :loading="loading" button-text="Unlock" />
      </div>
    </ToznyForm>
  </div>
</template>

<script>
import ToznyForm from '@/Common/ToznyForm'
import ToznyTextarea from '@/Common/ToznyTextarea'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'CredentialsForm',
  components: {
    ToznyForm,
    ToznyTextarea,
    ToznyButton,
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    loading: Boolean,
    validClient: String,
  },
  data() {
    return {
      credentials: '',
      validationErrors: [],
    }
  },
  computed: {
    credPlaceholder() {
      return `{
    "version": "*",
    "client_id": "********************",
    "client_email": "********************",
    "api_url": "********************",
    "api_key_id": "****************************************************************",
    "api_secret": "****************************************************************",
    "public_key": "*******************************************",
    "private_key": "*******************************************",
    "public_signing_key":  "*******************************************",
    "private_signing_key": "**************************************************************************************"
}`
    },
    formErrors() {
      const errors = [...this.validationErrors]
      if (this.error) {
        errors.push(this.error)
      }
      return errors.join(', ')
    },
  },
  methods: {
    handleSubmit() {
      this.validationErrors = []
      let credentials
      try {
        credentials = JSON.parse(this.credentials)
      } catch (e) {
        this.validationErrors = ['Invalid JSON']
        return
      }
      const errors = []
      if (!credentials.client_id) {
        errors.push('Missing your client ID')
      } else if (credentials.client_id !== this.validClient) {
        errors.push(
          `Only credentails with client ID ${this.validClient} may be used for this client`
        )
      }
      if (!credentials.public_key) {
        errors.push('Missing your public key')
      }
      if (!credentials.private_key) {
        errors.push('Missing your private key')
      }
      if (errors.length > 0) {
        this.validationErrors = errors
        return
      }
      this.$emit('submit', credentials)
    },
  },
}
</script>

<style></style>
