<template>
  <div class="inline-block">
    <input
      :id="id"
      :checked="checked"
      :disabled="disabled"
      @change="handleChange"
      type="checkbox"
      class="sr-only"
    />
    <label class="flex items-center cursor-pointer py-2" :for="id">
      <span class="inline-block mr-4" v-if="labelBefore">{{ label }}</span>
      <span
        class="inline-block relative border-2 border-gray-light switch-body"
      >
        <span
          class="block absolute bg-white border-2 border-gray-light rounded-full switch-circle"
        ></span>
      </span>
      <span class="inline-block ml-4" v-if="!labelBefore">{{ label }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'ToznySwitch',
  props: {
    label: String,
    labelBefore: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    value: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      checked: this.value,
      addInline: true,
    }
  },
  methods: {
    handleChange() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    },
  },
  watch: {
    value(direction) {
      this.checked = direction
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/scss/tailwind-variables.scss';

input[type='checkbox']:checked ~ label .switch-body {
  background-color: $colors-tozny;
}

input[type='checkbox']:not(:checked) ~ label .switch-body {
  background-color: $colors-gray-medium;
}

input[type='checkbox']:focus ~ label {
  .switch-circle {
    background-color: $colors-gray-light;
    border-color: $colors-gray-medium;
  }
  .switch-body {
    border-color: $colors-gray-medium;
  }
}

.switch-body {
  border-radius: 1.75em;
  height: 1.75em;
  width: 3.5em;
  transition: background-color 0.15s ease;
}

input[type='checkbox']:checked ~ label .switch-circle {
  transform: translateX(-50%) translate3d(1em, -50%, 0);
}

.switch-circle {
  height: 2.125em;
  width: 2.125em;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translate3d(-1em, -50%, 0);
  transition: transform 0.2s ease;
}

input[type='checkbox']:disabled ~ label {
  color: $colors-gray-dark;
  .switch-circle,
  .switch-body {
    background-color: $colors-gray-lightest;
    border-color: $colors-gray-light;
  }
}
</style>
