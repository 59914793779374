<template>
  <ListSelect
    @loadMore="$emit('more')"
    @select="(e) => $emit('select', e)"
    :has-more="hasMore"
    :loading="loading"
    :items="items"
    :active="active"
    title="Records"
  >
    <template #extras>
      <Actions
        :actions="cardActions"
        @action="handleActions"
        auto-id="record-viewer"
      />
    </template>
    <template #listItem="{ item }">
      <a
        :href="`${$route.path}/record/${item.id}`"
        class="no-underline w-full block"
      >
        <h4
          class="font-semibold text-base text-gray-darkest truncate"
          :title="item.type"
        >
          {{ item.type }}
        </h4>
        <p class="text-tiny text-gray-dark mb-0 truncate" :title="item.id">
          {{ item.id }}
        </p>
      </a>
    </template>
    <template #activeItem="{ item }">
      <slot :activeItem="item" />
    </template>
  </ListSelect>
</template>

<script>
import Actions from '@/Common/Actions'
import ListSelect from '@/Common/ListSelect'
export default {
  name: 'RecordViewer',
  components: {
    Actions,
    ListSelect,
  },
  props: {
    items: Array,
    active: String,
    hasMore: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      cardActions: [
        {
          event: 'new',
          altText: 'New Record',
          icon: 'add',
        },
      ],
    }
  },
  methods: {
    handleActions(action) {
      this.$emit(action.event)
    },
  },
}
</script>

<style></style>
