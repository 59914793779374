<template>
  <section>
    <ContentCard>
      <section id="basic" class="flex justify-between items-center p-4">
        <div>
          <h2 class="mb-0 text-md">{{ name }}</h2>
          <h3 class="font-normal text-gray-dark text-base">
            Client ID: {{ id }}
          </h3>
        </div>
        <EnabledSwitch
          v-if="!queen"
          :loading="statusUpdating"
          :enabled="enabled"
          :inactive="id === ''"
          @enabled="(e) => $emit('enabled', e)"
        />
        <span v-else class="text-sm text-gray-dark">Queen Client</span>
      </section>
      <section v-if="enabled && (id !== '') & !queen">
        <slot />
      </section>
    </ContentCard>
    <div v-if="loading" class="w-full flex items-center justify-center">
      <ToznyLoader class="text-tozny w-12 h-12" />
    </div>
    <div v-if="!enabled" class="text-center p-4 mb-8">
      <strong class="text-sm">Client Disabled</strong>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import ContentCard from '@/Common/ContentCard'
import EnabledSwitch from './EnabledSwitch'
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'ClientDetails',
  components: {
    ContentCard,
    EnabledSwitch,
    ToznyLoader,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    statusUpdating: Boolean,
    loading: Boolean,
    queen: Boolean,
  },
  methods: {},
}
</script>
