<template>
  <div>
    <ToznyLoader v-show="loading" class="h-4 w-4 text-tozny mr-2" />
    <ToznySwitch
      v-model="enabledSwitch"
      :label="enabledText"
      :disabled="loading || inactive"
      label-before
      id="enabled-switch"
    />
  </div>
</template>

<script>
import ToznySwitch from '@/Common/ToznySwitch'
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'EnabledSwitch',
  components: {
    ToznySwitch,
    ToznyLoader,
  },
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enabledSwitch: this.enabled,
    }
  },
  computed: {
    enabledText() {
      return this.enabledSwitch ? 'Enabled' : 'Disabled'
    },
  },
  watch: {
    enabledSwitch(direction) {
      this.$emit('enabled', direction)
    },
    enabled(direction) {
      this.enabledSwitch = direction
    },
  },
}
</script>

<style></style>
