<template>
  <MainLayout>
    <Banner
      v-if="clientStatus === 'error'"
      type="red"
      button-text="Retry"
      :click-method="initialize"
    >
      <p class="mb-0">{{ clientError }}</p>
    </Banner>
    <div class="mb-2">
      <router-link
        class="no-underline text-gray-dark inline-block"
        to="/clients"
      >
        <div class="flex items-center">
          <i class="material-icons micon">keyboard_arrow_left</i>
          Clients
        </div>
      </router-link>
    </div>
    <ClientDetails
      :name="clientName"
      :id="clientId"
      :enabled="clientEnabled"
      @enabled="setEnabled"
      :status-updating="enabledChanging"
      :loading="clientStatus === 'initializing'"
      :queen="isQueen"
    >
      <CredentialsForm
        v-if="clientStatus === 'locked'"
        @submit="unlockClient"
        :error="clientError"
        :valid-client="clientId"
        :loading="clientActivating"
      />
      <AdvancedControls
        v-if="clientStatus === 'active'"
        :credentials="clientCredentials"
      />
    </ClientDetails>
    <RecordViewer
      v-if="clientStatus === 'active'"
      :items="recordList"
      :has-more="recordsHasMore"
      :loading="recordsStatus === 'loading'"
      :active="this.$route.params.recordId"
      @select="handleRecordSelect"
      @more="moreRecords"
      @new="$router.push(`/clients/${clientId}/record/new`)"
    >
      <RecordError
        v-if="recordsStatus === 'error'"
        :message="recordsError"
        @action="initRecords"
        actionText="Try Again"
      />
      <router-view v-else />
    </RecordViewer>
  </MainLayout>
</template>

<script>
// @ is an alias to /src
import MainLayout from '@/Common/MainLayout/MainLayout'
import ClientDetails from './Components/ClientDetails'
import CredentialsForm from './Components/CredentialsForm'
import AdvancedControls from './Components/AdvancedControls'
import RecordViewer from './Record/Components/RecordViewer'
import RecordError from './Record/Components/RecordError'
import Banner from '@/Common/Banner'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'client',
  components: {
    Banner,
    MainLayout,
    ClientDetails,
    CredentialsForm,
    AdvancedControls,
    RecordViewer,
    RecordError,
  },
  computed: {
    ...mapState('clients/client', {
      clientStatus: 'status',
      clientError: 'errorMessage',
      clientId: 'clientId',
      enabledChanging: 'enabledChanging',
      clientActivating: 'activating',
    }),
    ...mapGetters('clients/client', {
      clientName: 'name',
      clientEnabled: 'enabled',
      clientCredentials: 'credentials',
      isQueen: 'isQueen',
    }),
    ...mapState('clients/client/records', {
      recordsStatus: 'status',
      recordsError: 'errorMessage',
      recordsHasMore: 'hasMore',
    }),
    ...mapGetters('clients/client/records', ['records']),
    clientIdParam() {
      return this.$route.params.clientId
    },
    recordList() {
      return this.records.map((r) => ({
        id: r.meta.recordId,
        type: r.meta.type,
      }))
    },
  },
  methods: {
    ...mapActions('clients/client', {
      initClient: 'initialize',
      setEnabled: 'setEnabled',
      unlockClient: 'activate',
    }),
    ...mapActions('clients/client/records', {
      initRecords: 'initialize',
      moreRecords: 'fetchNextPage',
    }),
    async initialize() {
      // If the client is not loaded or has changed
      if (
        this.clientId !== this.clientIdParam ||
        this.clientStatus === 'error'
      ) {
        await this.initClient(this.clientIdParam)
      }
      // If this is an active cleint, initialize reccords
      if (this.clientStatus === 'active') {
        await this.initRecords()
      }
    },
    handleRecordSelect(record) {
      const newPath = `/clients/${this.clientId}/record/${record.id}`
      if (this.$route.path === newPath) {
        return
      }
      this.$router.push(newPath)
    },
  },
  created: async function () {
    await this.initialize()
  },
  watch: {
    clientIdParam() {
      return this.initialize()
    },
    clientStatus() {
      return this.initialize()
    },
  },
}
</script>
