<template>
  <div>
    <div v-show="expanded" class="px-4 pt-2 border-t-2 border-gray-medium">
      <h4>Client Credentials</h4>
      <DataViewer :data="credentials" display="code" />
      <div class="flex items-center">
        <button
          id="download-button"
          @click.prevent="downloadRecord"
          type="button"
          class="bg-blue justify-content-center align-items-center hover:bg-gray-hvrover stroke-gray-menulight hover:stroke-white mt-10"
        >
          Download Configuration
        </button>
      </div>
    </div>
    <div class="text-center">
      <a
        href="#!"
        class="underline-none text-gray-dark block"
        @click.prevent="expand"
        :title="expandTitle"
      >
        <i class="material-icons micon">{{ expandIcon }}</i>
      </a>
    </div>
  </div>
</template>

<script>
import DataViewer from '@/Common/DataViewer'
export default {
  name: 'AdvancedControls',
  components: {
    DataViewer,
  },
  props: {
    credentials: Object,
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    expandIcon() {
      return this.expanded ? 'expand_less' : 'expand_more'
    },
    expandTitle() {
      return this.expanded ? 'Hide Advanced Controls' : 'Show Advanced Controls'
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    },
    downloadRecord() {
      const link = document.createElement('a')
      link.setAttribute(
        'href',
        'data:text/json;charset=utf-8,' +
          encodeURI(JSON.stringify(this.credentials))
      )
      link.setAttribute('download', 'e3db.json')
      link.style.display = 'none'
      link.click()
      link.remove()
    },
  },
}
</script>

<style></style>
